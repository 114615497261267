import * as React from 'react';
import { IButton, ButtonVariantValue, ICON_NAMES, ILink, ITestable } from '@belong/types';
import { BUTTON_VARIANTS } from '@belong/themes';
import { Document } from '@contentful/rich-text-types';

import { Button } from '../../actions/Button';

import ButtonWrapper from '../../styles/ButtonGroup';
import MaxWidthContainer from '../../styles/MaxWidthContainer';
import { Root, Heading, Body, BodyWrapper, Icon } from './styled';
import ContentFormatter from '../../styles/ContentFormatter';

export interface IIntroductionTextProps extends ITestable {
  heading: string;
  headingLevel?: 'h2' | 'h3';
  body?: Document;
  iconName?: ICON_NAMES;
  firstButton?: ILink;
  secondButton?: ILink;
}

const commonButtonProps: Pick<IButton, 'width'> = {
  width: {
    xs: 'full',
    md: 'default'
  }
};

const BUTTON_TYPE_ORDER = ['primary', 'secondary', 'tertiary', 'quaternary'];

export const shouldReverseButtonOrder = (
  leftSideButtonType: ButtonVariantValue,
  rightSideButtonType: ButtonVariantValue
): boolean => {
  return BUTTON_TYPE_ORDER.indexOf(leftSideButtonType) < BUTTON_TYPE_ORDER.indexOf(rightSideButtonType);
};

const IntroductionText: React.FC<IIntroductionTextProps> = ({
  iconName,
  heading,
  headingLevel,
  body,
  firstButton,
  secondButton,
  ...otherProps
}: IIntroductionTextProps) => {
  const testId = 'introduction-text';
  const testIdPrefix = otherProps['data-testid'] || '';

  const hasOnlyTertiaryOrQuaternaryButtons = [firstButton, secondButton]
    .filter(Boolean)
    .every(button => button?.variant === BUTTON_VARIANTS.TERTIARY || button?.variant === BUTTON_VARIANTS.QUATERNARY);

  const isOrderReversed = shouldReverseButtonOrder(firstButton?.variant, secondButton?.variant);

  return (
    <Root data-testid={testIdPrefix ? [testIdPrefix, testId].join('_') : testId}>
      <MaxWidthContainer
        restrictTo={{
          md: 10,
          lg: 8,
          xl: 6
        }}
      >
        {iconName && <Icon name={iconName} size="large" />}
        <Heading as={headingLevel}>{heading}</Heading>
        {body && (
          <BodyWrapper reduceSpacingAfter={hasOnlyTertiaryOrQuaternaryButtons}>
            <ContentFormatter>
              <Body html={body} />
            </ContentFormatter>
          </BodyWrapper>
        )}
        {(firstButton || secondButton) && (
          <ButtonWrapper
            hasControl={{
              xs: ['column', 'center', !isOrderReversed],
              md: ['row', 'center', isOrderReversed]
            }}
          >
            {firstButton && (
              <Button {...firstButton} {...commonButtonProps}>
                {firstButton.label}
              </Button>
            )}
            {secondButton && (
              <Button {...secondButton} {...commonButtonProps}>
                {secondButton.label}
              </Button>
            )}
          </ButtonWrapper>
        )}
      </MaxWidthContainer>
    </Root>
  );
};

IntroductionText.displayName = 'IntroductionText';

export default IntroductionText;
