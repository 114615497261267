import * as React from 'react';
import styled, { css } from 'styled-components';
import { TBreakpointKeys } from '@belong/types';
import { mediaMap } from '@belong/themes';

type PerBreakpoint<T> = { [k in TBreakpointKeys]?: T };

type ColumnSpans = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface IProps {
  restrictTo: ColumnSpans | PerBreakpoint<ColumnSpans>;
  debug?: boolean;
}

const columnWidths: { [k in TBreakpointKeys]: number } = {
  xs: 320 / 12, // todo: find a way to encode 100% as a number here
  sm: 14,
  md: 36,
  lg: 43,
  xl: 75
};

const columnGutterWidths: { [k in TBreakpointKeys]: number } = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 32
};

const getColumnWidth = (whichBreakpoint: TBreakpointKeys, howManyColumns: ColumnSpans): number => {
  const baseWidth = columnWidths[whichBreakpoint] * howManyColumns;
  const gutters = howManyColumns - 1;
  const gutterWidth = gutters * columnGutterWidths[whichBreakpoint];

  return baseWidth + gutterWidth;
};

const Root = styled.div<IProps>`
  margin-left: auto;
  margin-right: auto;

  ${props =>
    mediaMap(
      props.restrictTo,
      (maxCols: ColumnSpans, breakpoint: TBreakpointKeys) => css`
        ${props.debug &&
        css`
          &::before {
            content: 'Current Breakpoint: ${breakpoint}. Current Max Width: ${getColumnWidth(breakpoint, maxCols)}px';
          }
        `};

        max-width: ${getColumnWidth(breakpoint, maxCols)}px;
      `
    )};
`;

const MaxWidthContainer: React.FC<React.PropsWithChildren<IProps>> = (props: IProps) => {
  return <Root {...props} />;
};

MaxWidthContainer.displayName = 'MaxWidthContainer';
export default MaxWidthContainer;
