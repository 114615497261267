import React from 'react';
import { ThemeProvider } from 'styled-components';

import { IntroductionText as UI } from '@belong/ui-components';
import Section from '@belong/ui-components/components/styles/Section';
import { THEME_VARIATIONS } from '@belong/themes/theme-variations';
import { ITheme } from '@belong/types';
import { ISectionIntroductionText } from './model';

const IntroductionText: React.FC<ISectionIntroductionText> = ({ id, identifier, sectionStyles, ...props }) => {
  const currentTheme = sectionStyles?.themeVariation && THEME_VARIATIONS[sectionStyles.themeVariation];

  return (
    <ThemeProvider
      theme={(theme: ITheme) => ({
        ...theme,
        ...currentTheme
      })}
    >
      <Section
        data-identifier={identifier}
        data-contentid={id}
        data-testid={props['data-testid'] || 'section-introduction-text'}
        {...sectionStyles}
      >
        <UI {...props} />
      </Section>
    </ThemeProvider>
  );
};

export default IntroductionText;
