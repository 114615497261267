import { MaybeStyledCSS } from '@belong/types';
import { media } from '@belong/themes';

import styled, { css } from 'styled-components';

import { Heading as HeadingBase } from '../../styles/Typography/Heading';
import { RichText } from '../../styles/Typography/RichText/RichText';
import DefaultIcon from '../../Icon/Icon';

export const Icon = styled(DefaultIcon)`
  margin-bottom: 16px;
`;

export const Root = styled.section`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Heading = styled(HeadingBase)`
  margin-bottom: 8px;
  overflow-wrap: anywhere;

  ${media('md')`
    margin-bottom: 16px;
  `};
`;

export const Body = styled(RichText).attrs({
  alignment: 'center',
  contentLength: 'short'
})``;

export const BodyWrapper = styled.div<{ reduceSpacingAfter?: boolean }>`
  &:not(:last-child) {
    margin-bottom: 24px;

    ${media('md')`
      margin-bottom: 32px;
    `};

    ${(props): MaybeStyledCSS =>
      props.reduceSpacingAfter &&
      css`
        margin-bottom: 16px !important; // regardless of breakpoint
      `};
  }
`;
